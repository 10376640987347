'use client';
import { useEffect } from 'react';
import { useSession, signOut } from 'next-auth/react';

function SessionChecker() {
  const { data: session } = useSession();

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        const now = new Date();
        // Check if the session has expired
        if (session && session.expires && new Date(session.expires) < now) {
          // Trigger logout
          signOut();
        }
      },
      5 * 60 * 1000
    ); // Check every 5 minutes

    return () => clearInterval(intervalId);
  }, [session]);

  return null;
}

export default SessionChecker;
