import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseURL } from '@/utils/BaseURL';

export const listOpportunity = createAsyncThunk(
  'listOpportunity/fetchListOpportunity',
  async ({ session, status, isOrganizer, page }, { rejectWithValue }) => {
    try {
      let headers = {};
      let params = { page, page_size: 20, ordering: '-updated_at' };
      if (status === 'authenticated' && session) {
        const token = session?.accessToken;
        headers = { Authorization: `Bearer ${token}` };
      }
      if (!isOrganizer) {
        params = {
          ...params,
          is_verified: true,
          is_active: true,
        };
      }

      const response = await axios.get(`${baseURL}/opportunity/list/`, {
        headers,
        params,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const listOppById = createAsyncThunk(
  'listOpportunity/fetchListOppById',
  async ({ id, token = null, isEditing = false }, { rejectWithValue }) => {
    let url = isEditing
      ? `${baseURL}/opportunity/${id}/?editable=true`
      : `${baseURL}/opportunity/${id}/`;
    try {
      let headers = {};
      if (token) {
        headers = { Authorization: `Bearer ${token}` };
      }

      const response = await axios.get(url, {
        headers,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  loading: false,
  data: null,
  isError: false,
  error: null,
};
const listOpportunitySlice = createSlice({
  name: 'listOpportunity',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(listOpportunity.pending, state => {
        state.loading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(listOpportunity.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(listOpportunity.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.error = action.payload;
      });
    builder
      .addCase(listOppById.pending, state => {
        state.loading = true;
        state.isError = false;
        state.error = null;
      })
      .addCase(listOppById.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(listOppById.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
      });
  },
});

export const { reset } = listOpportunitySlice.actions;
export default listOpportunitySlice.reducer;
