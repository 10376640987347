'use client';
import React, { useEffect, useLayoutEffect, useState, Suspense } from 'react';
import G from '../../assets/images/G.webp';
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Button,
  NavbarMenuItem,
  NavbarMenuToggle,
  NavbarMenu,
  Dropdown,
  DropdownTrigger,
  DropdownItem,
  DropdownMenu,
} from '@nextui-org/react';

import Avatar from '@/components/Avatar';

import {
  RocketIcon,
  Megaphone,
  HeartHandshake,
  Key,
  LogIn,
} from 'lucide-react';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import Image from 'next/image';
import { signOut, useSession } from 'next-auth/react';
import { baseURL } from '@/utils/BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function PrimaryNavbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [flyer, setFlyer] = useState(false);
  const [flyerTwo, setFlyerTwo] = useState(false);
  const pathname = usePathname();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [profileDropdownItems, setProfileDropdownItems] = useState([]);
  useEffect(() => {
    if (userData?.organizations.length > 0) {
      setProfileDropdownItems([
        { key: 'profilePage', label: 'Profile', href: '/profile' },
        {
          key: 'organization',
          label: 'Your Organization',
          href: `/organization/${userData.organizations[0]}`,
        },
        { key: 'logout', label: 'Logout', onClick: () => signOut() },
      ]);
    } else {
      setProfileDropdownItems([
        { key: 'profilePage', label: 'Profile', href: '/profile' },
        { key: 'logout', label: 'Logout', onClick: () => signOut() },
      ]);
    }
  }, [userData]);

  const { data: session, status } = useSession();

  useLayoutEffect(() => {
    if (status === 'authenticated') {
      if (session?.error === 'RefreshAccessTokenError') {
        toast.error('Session Expired, Please Login Again');
      }
      axios
        .get(`${baseURL}/profile/`, {
          headers: {
            Authorization: `Bearer ${session?.accessToken}`,
          },
        })
        .then(response => {
          setUserData(response.data.data);
          setIsLoggedIn(true);
        })
        .catch(error => {
          console.log(error);
          //   signOut();
        });
    }
  }, [session, status]);
  // Check if the current route is '/'
  const isHomeRoute = pathname === '/';
  const OrganizeEvent = pathname === '/organize-event';
  const Support = pathname === '/support';

  const navbarItems = [
    {
      icon: <RocketIcon size={20} />,
      name: 'Events',
      href: '/events',
    },
    {
      icon: <Megaphone size={20} />,
      name: 'Organize',
      href: '/organize-event',
    },
    {
      icon: <HeartHandshake size={20} />,
      name: 'Support',
      href: '/support',
    },
  ];

  const menuItems = [
    {
      icon: <RocketIcon size={20} />,
      name: 'Events',
      href: '/events',
    },
    {
      icon: <Megaphone size={20} />,
      name: 'Demo',
      href: '/organize-event',
    },
    {
      icon: <HeartHandshake size={20} />,
      name: 'Support',
      href: '/support',
    },
  ];

  const handleItemClick = () => {
    setIsMenuOpen(false); // Close the flyer immediately after clicking a navigation item
  };

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const LoginButtonWebSuspense = () => {
    const utmSearchParams = useSearchParams().get('utm');
    return (
      <React.Fragment>
        <Button
          className="bg-gradient-to-r from-[#ff4721] to-[#ffa52b] text-white"
          size="lg"
          as={Link}
          href={`/auth?redirect=${pathname}${utmSearchParams ? '&utm=' + utmSearchParams : ''}`}
        >
          Login
        </Button>
      </React.Fragment>
    );
  };
  const LoginButtonMobileSuspense = () => {
    const utmSearchParams = useSearchParams().get('utm');
    return (
      <React.Fragment>
        <NavbarMenuItem onClick={handleItemClick}>
          <Link
            href={`/auth?redirect=${pathname}${utmSearchParams ? '&utm=' + utmSearchParams : ''}`}
            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
          >
            <div className="text-base font-medium text-white flex items-center gap-3">
              <LogIn size={20} />
              Login / Sign Up
            </div>
          </Link>
        </NavbarMenuItem>
      </React.Fragment>
    );
  };

  return (
    <Navbar
      onMenuOpenChange={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
      maxWidth="full"
      position="sticky"
      className={`bg-[#6754e3] w-full text-white h-15 z-50 transition-all duration-300 ease-in-out`}
    >
      <NavbarContent className="flex justify-between items-center">
        <NavbarBrand>
          <Link href="/" className="font-bold text-inherit text-xl">
            <Image src={G} width={180} alt="" priority />
          </Link>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent className="sm:hidden" justify="end">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
        />
      </NavbarContent>

      <NavbarContent className="hidden sm:flex gap-5" justify="center">
        {navbarItems.map((item, index) => {
          return (
            <NavbarItem key={index}>
              <Link
                href={item.href}
                className="flex flex-col items-center justify-center gap-1 text-base"
              >
                <span className="text-base">{item.icon}</span>
                {item.name}
              </Link>
            </NavbarItem>
          );
        })}
        {isLoggedIn ? (
          <div className="">
            <Dropdown>
              <DropdownTrigger>
                <div
                  className="text-base font-medium py-2 gap-2 flex flex-row"
                  onMouseEnter={() => setFlyerTwo(true)}
                  onMouseLeave={() => setFlyerTwo(false)}
                >
                  <span className="flex flex-col items-center justify-center gap-2 ">
                    {userData?.profile_picture ? (
                      <Avatar profileImageUrl={userData?.profile_picture} />
                    ) : (
                      <Avatar
                        userName={
                          userData?.first_name + ' ' + userData?.last_name
                        }
                      />
                    )}
                  </span>
                </div>
              </DropdownTrigger>
              <DropdownMenu aria-label="Profile Actions" align="end">
                {profileDropdownItems.map(item => (
                  <DropdownItem
                    key={item.key}
                    color="primary"
                    onClick={
                      item.onClick ? item.onClick : () => router.push(item.href)
                    }
                  >
                    {item.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        ) : (
          <Suspense fallback={<div>Loading...</div>}>
            <LoginButtonWebSuspense />
          </Suspense>
        )}
      </NavbarContent>
      <NavbarContent justify="end "></NavbarContent>
      <NavbarMenu className="h-fit pt-4 bg-primary">
        {menuItems.map((item, index) => (
          <NavbarMenuItem
            key={`${item}-${index}`}
            className="flex flex-col gap-4"
            onClick={handleItemClick}
          >
            <Link
              href={item.href}
              className="w-full text-white flex gap-4 flex-row items-center  text-base font-medium  "
            >
              {item.icon}
              {item.name}
            </Link>
          </NavbarMenuItem>
        ))}
        <NavbarMenuItem>
          {isLoggedIn ? (
            <>
              <hr className="divider" />
              <div className="text-base font-semibold text-white  gap-2 flex flex-row items-center pt-3">
                {userData?.profile_picture ? (
                  <Avatar profileImageUrl={userData?.profile_picture} />
                ) : (
                  <Avatar
                    userName={userData?.first_name + ' ' + userData?.last_name}
                  />
                )}
                {userData?.first_name + ' ' + userData?.last_name}
              </div>
              <Dropdown>
                <DropdownMenu aria-label="Profile Actions" align="start">
                  {profileDropdownItems.map(item => (
                    <DropdownItem
                      key={item.key}
                      color="primary"
                      onClick={
                        item.onClick
                          ? item.onClick
                          : () => handleItemClick && router.push(item.href)
                      }
                    >
                      {item.label}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </>
          ) : (
            <div>
              (
              <Suspense fallback={<div>Loading...</div>}>
                <LoginButtonMobileSuspense />
              </Suspense>
              )
            </div>
          )}
        </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
}
