import React from 'react';

const Avatar = ({ userName, profileImageUrl }) => {
  const getInitials = name => {
    let initials = name
      .split(' ')
      .map(word => word[0])
      .join('');
    return initials.length > 1 ? initials.substring(0, 2) : initials;
  };

  const avatarStyle = {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.5rem', // Adjust size as needed
    width: '3.5rem', // Adjust size as needed
    height: '3.5rem', // Adjust size as needed
    background: 'white', // Adjust background color as needed
    color: 'black',
    textAlign: 'center',
    lineHeight: '3.5rem', // Adjust line height to match height for vertical centering
    borderRadius: '50%',
    cursor: 'pointer',
    overflow: 'hidden', // Ensure the image does not overflow the circular boundary
    border: '1px solid #e0e0e0', // Add a border to the circular boundary
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Ensure the image covers the area without distorting aspect ratio
  };

  return (
    <div style={avatarStyle}>
      {profileImageUrl ? (
        <img src={profileImageUrl} alt="Avatar" style={imageStyle} />
      ) : (
        getInitials(userName).toUpperCase()
      )}
    </div>
  );
};

export default Avatar;



