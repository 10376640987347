'use client';
import Footer from './Footer';
import PrimaryNavbar from './PrimaryNavbar';
import { useSession } from 'next-auth/react';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';

const Layout = ({ children }) => {
  const { data: session, status } = useSession();

  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <main>
      <div>
        <Toaster />
      </div>

      <PrimaryNavbar />

      {children}
      <Footer />
    </main>
  );
};

export { Layout };
